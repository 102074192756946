<template>
  <div class="rolesWrap">
    <el-table
      :data="tableDataInfo.result"
      style="width: 100%"
      class="rolesTable"
      border
    >
      <el-table-column
        v-for="c in tableColumns"
        :key="c.prop"
        :label="c.label"
        :width="c.width"
      >
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row[c.prop] }}</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableColumns: [
        {
          label: "角色名称",
          prop: "role_name",
        },
      ],
      tableDataInfo: {},
    };
  },
  mounted() {
    this.getTableData({});
  },
  methods: {
    getTableData() {
      this.$request.queryRoles({ page_num: 1, page_size: 8 }).then((res) => {
        if (res.status != "success") {
          this.$message.error(res.msg);
          return;
        }
        this.tableDataInfo = res.data || {};
      });
    },
  },
};
</script>

<style lang='scss'>
.rolesWrap {
  padding: 24px;
  background-color: #fff;
  .rolesTable {
    .normal {
      color: #67c23a;
    }
    .forbid {
      color: #f56c6c;
    }
  }
}
</style>